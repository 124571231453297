import React from "react";
import { Box, Typography, Button } from "@mui/material";
import { styled } from "@mui/material/styles";
import "@fontsource/poppins";

// Styled Components
const Container = styled(Box)(({ theme }) => ({
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
  justifyContent: "center",
  minHeight: "100vh",
  backgroundColor: "#f9f9f9",
  fontFamily: "Poppins",
}));

const ContentBox = styled(Box)(({ theme }) => ({
  backgroundColor: "#fff",
  borderRadius: "12px",
  padding: theme.spacing(4),
  boxShadow: "0 4px 10px rgba(0, 0, 0, 0.1)",
  textAlign: "center",
  maxWidth: "800px",
  marginTop: theme.spacing(0),
}));

const Logo = styled("img")(({ theme }) => ({
    maxWidth: "100%",
    height: "auto",
    borderRadius: "12px",
    marginBottom: theme.spacing(2),
  }));
  

const ConfirmButton = styled(Button)(({ theme }) => ({
  marginTop: theme.spacing(4),
  backgroundColor: "#AC8C46",
  color: "#fff",
  borderRadius: "8px",
  padding: theme.spacing(1.5, 4),
  fontFamily: "Poppins",
  "&:hover": {
    backgroundColor: theme.palette.grey[700],
  },
}));

const VerificationSuccess = () => {
  return (
    <Container>
      <ContentBox>
        <Logo
          src="https://tlqnsnbcxrsnrveulfiw.supabase.co/storage/v1/object/sign/emailImg/logo4.png?token=eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJ1cmwiOiJlbWFpbEltZy9sb2dvNC5wbmciLCJpYXQiOjE3MzEzMjkwNzgsImV4cCI6MTc2Mjg2NTA3OH0.Qtx0UgpfFO_IyWFI1DbnkNuUp1nTIvGDvtHYfdddwj8&t=2024-11-11T12%3A44%3A38.466Z"
          alt="Logo"
        />
        <Typography
          variant="h5"
          component="h1"
          sx={{ fontWeight: "bold", fontFamily: "Poppins", color: "#333" }}
        >
          ¡Cuenta Verificada!
        </Typography>
        <Typography
          variant="body1"
          sx={{
            color: "#555",
            marginTop: 2,
            lineHeight: 1.6,
            fontFamily: "Poppins",
          }}
        >
          Gracias por confirmar tu correo. Ahora puedes iniciar sesión y
          disfrutar de nuestra plataforma.
        </Typography>
        <ConfirmButton href="/login">Ir a Iniciar Sesión</ConfirmButton>
      </ContentBox>
    </Container>
  );
};

export default VerificationSuccess;
