import React, { useEffect } from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import HomepageScreen from './pages/homepageScreen';
import RegisterScreen from './pages/registerScreen';
import ArtistProfileScreen from './pages/artistProfileScreen';
import ArtistSearchScreen from './pages/artistSearchScreen';
import SigInScreen from './pages/SignInPage';
import CreateFaroPage from './pages/faroCreationScreen';
import ClientDashboard from './pages/clientDashboard';
import Header from './components/Header/Header';
import Footer from './components/Footer/Footer';
import Analytics from './Analytics';
import AnalyticsTracker from './components/AnalyticsTracker';
import VerificationSuccess from './pages/VerificationSuccess';

function App() {
    useEffect(() => {
        Analytics.initAnalytics();
    }, []);

    return (
        <Router>
            <AnalyticsTracker />
            <Header />
            <Routes>
                <Route path="/" element={<HomepageScreen />} />
                <Route path="/register" element={<RegisterScreen />} />
                <Route path="/profile/artist/:artistName" element={<ArtistProfileScreen />} />
                <Route path="/search" element={<ArtistSearchScreen />} />
                <Route path="/login" element={<SigInScreen />} />
                <Route path="/faro_creation" element={<CreateFaroPage />} />
                <Route path="/faro_creation/:faroId" element={<CreateFaroPage />} />
                <Route path="/dashboard/client" element={<ClientDashboard />} />
                <Route path="/verification-success" element={<VerificationSuccess />} />
            </Routes>
            <Footer />
        </Router>
    );
}

export default App;
